function createObject() {
  let example = {
    id: "LBST_ZV_Sortieren_Zaehlen",
    left: "weniger ausgeprägte <br /> Zählkompetenz",
    right: "ausgeprägte <br /> Zählkompetenz",
    showGroupNames: false,
    maximalAmountElementsInGroup: 5,
    numberOfPlaceholder: 5,
    checkAfterHowManyItems: 5,
    checkAfterExactItemCount: true,
    workOrder: `Hier sehen Sie fünf Aussagen aus unterschiedlichen Phasen der Entwicklung der flexiblen Zahlwortreihe. Ziehen Sie die Aussagen auf die Linie und sortieren Sie sie nach ihren Fortschritten in der Zahlwortreihe. Zahlwortreihen, welche am Anfang der Entwicklung stehen sind dabei links und Zahlwortreihen, welche einen hohen Grad an Flexibilität zeigen rechts. Sobald Sie alle fünf Aussagen angeordnet haben, drücke Sie auf „Überprüfen“, um eine Rückmeldung zu erhalten`,
    possibleCategories: [
      {
        id: 1,
        name: "1",
      },
      {
        id: 2,
        name: "2",
      },
      {
        id: 3,
        name: "3",
      },
      {
        id: 4,
        name: "4",
      },
      {
        id: 5,
        name: "5",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/zv/Sortieren/zaehlen/01.png"),
        rank: 3,
        flex: 2,
      },
      {
        id: 2,
        image: require("@/assets/zv/Sortieren/zaehlen/02.png"),
        rank: 5,
        flex: 2,
      },
      {
        id: 3,
        image: require("@/assets/zv/Sortieren/zaehlen/03.png"),
        rank: 1,
        flex: 2,
      },
      {
        id: 4,
        image: require("@/assets/zv/Sortieren/zaehlen/04.png"),
        rank: 2,
        flex: 2,
      },
      {
        id: 5,
        image: require("@/assets/zv/Sortieren/zaehlen/05.png"),
        rank: 4,
        flex: 2,
      },
    ],
  };

  const richtig =
    "Prima, Sie haben die Aussagen in die korrekte Reihenfolge nach den Entwicklungsphasen der Zählentwicklung (vgl. Böning et al., 2017) sortiert! ";

  const fehler = "Das ist noch nicht ganz richtig. ";

  const error = "Diese Anordnung ist noch nicht richtig. ";

  const tipEins =
    "Schauen Sie sich die Einordnung von der Aussage von Marie nochmal an. ";

  const tipZwei =
    "Schauen Sie sich die Einordnung von der Aussage von Tom nochmal an. ";

  const tipDrei =
    "Schauen Sie sich die Einordnung von der Aussage von Finn nochmal an. ";

  const tipVier =
    "Schauen Sie sich die Einordnung von der Aussage von Lina nochmal an. ";

  const tipFuenf =
    "Schauen Sie sich die Einordnung von der Aussage von Merve nochmal an. ";

  const tipVor =
    "Schauen Sie in welchen Aussagen die Kinder die Zahlfolge als Ganzes sehen und in welchen diese einzeln wahrgenommen werden. Achten Sie auch darauf, wie flexibel das Zählen ist. ";

  const tipReihen =
    "Beginnen Sie mit der Aussage, welche die wenigste Flexibilität in der Zahlwortreihe aufzeigt links und ordnen sie die Aussagen von dort mit stärker werdender Flexibilität. ";

  example.responses = [
    {
      id: "12345",
      text: richtig,
    },
    {
      id: "12543",
      text: error + tipVor,
    },
    {
      id: "13254",
      text: error + tipVor,
    },
    {
      id: "13425",
      text: error + tipVor,
    },
    {
      id: "13452",
      text: error + tipVor,
    },
    {
      id: "13524",
      text: error + tipVor,
    },
    {
      id: "13542",
      text: error + tipVor,
    },
    {
      id: "14253",
      text: error + tipVor,
    },
    {
      id: "14325",
      text: error + tipVor,
    },
    {
      id: "14352",
      text: error + tipVor,
    },
    {
      id: "14523",
      text: error + tipVor,
    },
    {
      id: "14532",
      text: error + tipVor,
    },
    {
      id: "15243",
      text: error + tipVor,
    },
    {
      id: "15324",
      text: error + tipVor,
    },
    {
      id: "15342",
      text: error + tipVor,
    },
    {
      id: "15423",
      text: error + tipVor,
    },
    {
      id: "15432",
      text: error + tipVor,
    },
    {
      id: "21354",
      text: error + tipVor,
    },
    {
      id: "21435",
      text: error + tipVor,
    },
    {
      id: "21453",
      text: error + tipVor,
    },
    {
      id: "21534",
      text: error + tipVor,
    },
    {
      id: "21543",
      text: error + tipVor,
    },
    {
      id: "23154",
      text: error + tipVor,
    },
    {
      id: "23514",
      text: error + tipVor,
    },
    {
      id: "23541",
      text: error + tipVor,
    },
    {
      id: "24135",
      text: error + tipVor,
    },
    {
      id: "24153",
      text: error + tipVor,
    },
    {
      id: "24315",
      text: error + tipVor,
    },
    {
      id: "24351",
      text: error + tipVor,
    },
    {
      id: "24513",
      text: error + tipVor,
    },
    {
      id: "24531",
      text: error + tipVor,
    },
    {
      id: "25134",
      text: error + tipVor,
    },
    {
      id: "25143",
      text: error + tipVor,
    },
    {
      id: "25314",
      text: error + tipVor,
    },
    {
      id: "25341",
      text: error + tipVor,
    },
    {
      id: "25413",
      text: error + tipVor,
    },
    {
      id: "25431",
      text: error + tipVor,
    },
    {
      id: "31254",
      text: error + tipVor,
    },
    {
      id: "31425",
      text: error + tipVor,
    },
    {
      id: "31452",
      text: error + tipVor,
    },
    {
      id: "31524",
      text: error + tipVor,
    },
    {
      id: "31542",
      text: error + tipVor,
    },
    {
      id: "32145",
      text: error + tipVor,
    },
    {
      id: "32154",
      text: error + tipVor,
    },
    {
      id: "32415",
      text: error + tipVor,
    },
    {
      id: "32451",
      text: error + tipVor,
    },
    {
      id: "32514",
      text: error + tipVor,
    },
    {
      id: "32541",
      text: error + tipVor,
    },
    {
      id: "34125",
      text: error + tipVor,
    },
    {
      id: "34152",
      text: error + tipVor,
    },
    {
      id: "34215",
      text: error + tipVor,
    },
    {
      id: "34251",
      text: error + tipVor,
    },
    {
      id: "34512",
      text: error + tipVor,
    },
    {
      id: "34521",
      text: error + tipVor,
    },
    {
      id: "35124",
      text: error + tipVor,
    },
    {
      id: "35142",
      text: error + tipVor,
    },
    {
      id: "35214",
      text: error + tipVor,
    },
    {
      id: "35241",
      text: error + tipVor,
    },
    {
      id: "35412",
      text: error + tipVor,
    },
    {
      id: "35421",
      text: error + tipVor,
    },
    {
      id: "41253",
      text: error + tipVor,
    },
    {
      id: "41325",
      text: error + tipVor,
    },
    {
      id: "41352",
      text: error + tipVor,
    },
    {
      id: "41523",
      text: error + tipVor,
    },
    {
      id: "41532",
      text: error + tipVor,
    },
    {
      id: "42135",
      text: error + tipVor,
    },
    {
      id: "42153",
      text: error + tipVor,
    },
    {
      id: "42315",
      text: error + tipVor,
    },
    {
      id: "42351",
      text: error + tipVor,
    },
    {
      id: "42513",
      text: error + tipVor,
    },
    {
      id: "42531",
      text: error + tipVor,
    },
    {
      id: "43125",
      text: error + tipVor,
    },
    {
      id: "43152",
      text: error + tipVor,
    },
    {
      id: "43215",
      text: error + tipVor,
    },
    {
      id: "43251",
      text: error + tipVor,
    },
    {
      id: "43512",
      text: error + tipVor,
    },
    {
      id: "43521",
      text: error + tipVor,
    },
    {
      id: "45123",
      text: error + tipVor,
    },
    {
      id: "45132",
      text: error + tipVor,
    },
    {
      id: "45213",
      text: error + tipVor,
    },
    {
      id: "45231",
      text: error + tipVor,
    },
    {
      id: "45312",
      text: error + tipVor,
    },
    {
      id: "45321",
      text: error + tipVor,
    },
    {
      id: "51243",
      text: error + tipVor,
    },
    {
      id: "51324",
      text: error + tipVor,
    },
    {
      id: "51342",
      text: error + tipVor,
    },
    {
      id: "51423",
      text: error + tipVor,
    },
    {
      id: "51432",
      text: error + tipVor,
    },
    {
      id: "52134",
      text: error + tipVor,
    },
    {
      id: "52143",
      text: error + tipVor,
    },
    {
      id: "52314",
      text: error + tipVor,
    },
    {
      id: "52341",
      text: error + tipVor,
    },
    {
      id: "52413",
      text: error + tipVor,
    },
    {
      id: "52431",
      text: error + tipVor,
    },
    {
      id: "53124",
      text: error + tipVor,
    },
    {
      id: "53142",
      text: error + tipVor,
    },
    {
      id: "53214",
      text: error + tipVor,
    },
    {
      id: "53241",
      text: error + tipVor,
    },
    {
      id: "53412",
      text: error + tipVor,
    },
    {
      id: "53421",
      text: error + tipVor,
    },
    {
      id: "54123",
      text: error + tipVor,
    },
    {
      id: "54132",
      text: error + tipVor,
    },
    {
      id: "54213",
      text: error + tipVor,
    },
    {
      id: "54231",
      text: error + tipVor,
    },
    {
      id: "54312",
      text: error + tipVor,
    },
    {
      id: "12354",
      text: fehler + tipVier,
    },
    {
      id: "12435",
      text: fehler + tipVier,
    },
    {
      id: "12453",
      text: fehler + tipDrei,
    },
    {
      id: "12534",
      text: fehler + tipFuenf,
    },
    {
      id: "13245",
      text: fehler + tipDrei,
    },
    {
      id: "14235",
      text: fehler + tipVier,
    },
    {
      id: "15234",
      text: fehler + tipFuenf,
    },
    {
      id: "31245",
      text: fehler + tipDrei,
    },
    {
      id: "21345",
      text: fehler + tipZwei,
    },
    {
      id: "23145",
      text: fehler + tipEins,
    },
    {
      id: "23415",
      text: fehler + tipEins,
    },
    {
      id: "23451",
      text: fehler + tipEins,
    },
    {
      id: "41235",
      text: fehler + tipVier,
    },
    {
      id: "51234",
      text: fehler + tipFuenf,
    },
    {
      id: "54321",
      text: tipReihen,
    },
  ];

  return example;
}

export default createObject();
