<template>
  <div>
    <h2 class="faledia-caption">Grundvorstellungen von Zahlen</h2>
    <v-card outlined>
      <v-card-title>Was sollen Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            Anzahlen basierend auf der kardinalen Zahlvorstellung strukturiert
            erfassen.
          </li>
          <li>
            die durchlaufene Zahlenfolge basierend auf der ordinalen
            Zahlvorstellung in den Blick nehmen.
          </li>
          <li>
            die dem Zählen zugrunde liegenden Prinzipien implizit anwenden.
          </li>
          <li>sicher und flexibel zählen.</li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <h3 class="faledia-caption">
      Was sollten Sie zu Grundvorstellungen wissen?
    </h3>
    <p>
      Eine Zahl kann aus unterschiedlichen Perspektiven betrachtet werden.
      Weithin werden die kardinale und die ordinale Zahlvorstellung als die
      beiden besonders zentralen Zahlaspekte angesehen. Die kardinale
      Zahlvorstellung nimmt die Mächtigkeit der Menge in den Blick und ist die
      Grundlage dafür, Anzahlen von Mengen zu bestimmen, ohne die Elemente
      einzeln zu zählen. Ein Mengenverständnis entwickelt sich durch Vergleiche
      von Mengen, aber auch durch deren Veränderung oder Zusammensetzung aus
      Teilmengen (Resnick, 1989).
    </p>
    <p>
      Der ordinale Zahlaspekt kann unterteilt werden in den Zählzahlaspekt, bei
      dem die durchlaufene Zahlenfolge in den Blick gerät, und den
      Ordnungszahlaspekt. Mit einer Ordnungszahl wird die Position eines
      Elementes in der Zahlwortreihe angegeben. Unter diesem Blickwinkel ist die
      Bezeichnung der Beziehung des Elementes zum Rest der Reihenfolge
      ausschlaggebend (Benaceraff, 1984). Einer Zahl kommt beim ordinalen
      Zahlaspekt somit immer erst im Verhältnis zu den sie umgebenden Zahlen
      eine Bedeutung zu.
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td colspan="2"><strong>Zahlaspekt</strong></td>
            <td><strong>Erläuterung</strong></td>
            <td><strong>Beispiel</strong></td>
          </tr>
          <tr>
            <td colspan="2"><strong>Kardinalzahl</strong></td>
            <td>Anzahl der Elemente ("Mächtigkeit einer Menge")</td>
            <td class="no-wrap">
              <v-row no-gutters>7 Brote,</v-row>
              <v-row no-gutters>3 Kugelschreiber</v-row>
            </td>
          </tr>
          <tr>
            <td rowspan="2"><strong>Ordinalzahl</strong></td>
            <td><strong>Zählzahl</strong></td>
            <td>
              Element einer Zahlenfolge bestehend aus aufeinanderfolgend
              natürlichen Zahlen
            </td>
            <td class="no-wrap">
              <v-row no-gutters>"eins, zwei, drei, vier"</v-row>
              <v-row no-gutters>"zehn, neun, acht"</v-row>
            </td>
          </tr>
          <tr>
            <td><strong>Ordnungszahl</strong></td>
            <td>Position eines Elementes in einer geordneten Reihe</td>
            <td>"Die siebte in der Warteschlange"</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>
      Eine zentrale Bedeutung für das Zahlverständnis trägt das Zählen. Bereits
      im Vorschulalter beginnen Kinder, zählende mathematische Früherfahrungen
      zu sammeln. Hier ist es häufig, dass die Kinder die Zahlenreihe als
      zusammenhängendes Wort reproduzieren (z. B. Mack 2005). Beim Zählvorgang
      ist sowohl die Beherrschung dieser Zahlwortreihe notwendig als auch die
      Fähigkeit, Mengen abzuzählen (Hasemann & Gasteiger, 2014). Fünf
      grundlegende Zählprinzipien können hierbei unterschieden werden, die für
      ein erfolgreiches Zählen unabdingbar sind:
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>Prinzip der stabilen Reihenfolge</strong></td>
            <td>
              Die Zahlwörter stehen in einer festen Reihenfolge und wird in
              dieser beim Zählen durchlaufen.
            </td>
          </tr>
          <tr>
            <td><strong>Eins-Eins-Prinzip</strong></td>
            <td>
              Jedem Zahlwort wird ein zu zählendes Element zugeordnet. Genauso
              wird jedes Element nur durch ein Zahlwort beschrieben. Somit wird
              kein Element ausgelassen oder doppelt gezählt.
            </td>
          </tr>
          <tr>
            <td><strong>Kardinalprinzip</strong></td>
            <td>
              Das zuletzt genannte Zahlwort drückt die Mächtigkeit der Menge
              aus.
            </td>
          </tr>
          <tr>
            <td><strong>Prinzip der Irrelevanz der Reihenfolge</strong></td>
            <td>
              Die Elemente können in einer beliebigen Anordnung und Reihenfolge
              gezählt werden.
            </td>
          </tr>
          <tr>
            <td><strong>Abstraktionsprinzip</strong></td>
            <td>
              Jede Form von Elementen kann auf diese Weise gezählt werden. Dies
              bezieht sich z.B. auch auf Töne.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen[0].id"
        :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen[0].showGroupNames"
        :workOrder="LBSTGruppen[0].workOrder"
        :workOrderImage="LBSTGruppen[0].workOrderImage"
        :possibleCategories="LBSTGruppen[0].possibleCategories"
        :elements="LBSTGruppen[0].elements"
        :responses="LBSTGruppen[0].responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      Abstraktionsprinzip
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Die Form der gezählten Elemente ist beliebig. Somit können
                    auch Plättchen gezählt werden.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Kardinalprinzip
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Die Aussage "es sind sechs Plättchen" unterstreicht, dass
                    das letztgenannte Zahlwort die Mächtigkeit der Menge
                    ausdrückt.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Prinzip der stabilen Reihenfolge
                    </strong>
                  </td>
                  <td>
                    nicht erkennbar
                  </td>
                  <td>
                    Es kann keine Aussage darüber getroffen werden, in welcher
                    Reihenfolge die Zahlwörter genutzt wurden.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Prinzip der Irrelevanz der Reihenfolge
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Die Elemente können in einer beliebigen Anordnung und
                    Reihenfolge gezählt werden.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Eins-Eins-Prinzip
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Jedem Plättchen wird genau ein Zahlwort zugeordnet.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>
    <p>
      Es zeigt sich, dass anhand des oberen Dokuments einige Zählprinzipien
      erkannt werden können. Wie sieht dies aber im nächsten Beispiel aus?
    </p>

    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen[1].id"
        :checkAfterHowManyItems="LBSTGruppen[1].checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen[1].checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen[1].showGroupNames"
        :workOrder="LBSTGruppen[1].workOrder"
        :workOrderImage="LBSTGruppen[1].workOrderImage"
        :possibleCategories="LBSTGruppen[1].possibleCategories"
        :elements="LBSTGruppen[1].elements"
        :responses="LBSTGruppen[1].responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      Abstraktionsprinzip
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Die Form der gezählten Elemente ist beliebig. Somit können
                    auch Plättchen gezählt werden.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Kardinalprinzip
                    </strong>
                  </td>
                  <td>
                    nicht erkennbar
                  </td>
                  <td>
                    Es wird keine explizite Aussage über die Anzahl der
                    Plättchen getroffen.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Prinzip der stabilen Reihenfolge
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Die Anordnung und die Aussage legen nahe, dass auch die
                    Zahlwörter in einer festen Reihenfolge durchlaufen wurden.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Prinzip der Irrelevanz der Reihenfolge
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Die Elemente können in einer beliebigen Anordnung und
                    Reihenfolge gezählt werden.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Eins-Eins-Prinzip
                    </strong>
                  </td>
                  <td>
                    erfüllt
                  </td>
                  <td>
                    Jedem Plättchen wird genau ein Zahlwort zugeordnet.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>

    <AppButtonCollapse>
      <div style="background-color: white">
        <LernbausteinGruppen
          :id="LBSTGruppen[2].id"
          :checkAfterHowManyItems="LBSTGruppen[2].checkAfterHowManyItems"
          :checkAfterExactItemCount="LBSTGruppen[2].checkAfterExactItemCount"
          :showGroupNames="LBSTGruppen[2].showGroupNames"
          :workOrder="LBSTGruppen[2].workOrder"
          :workOrderImage="LBSTGruppen[2].workOrderImage"
          :possibleCategories="LBSTGruppen[2].possibleCategories"
          :elements="LBSTGruppen[2].elements"
          :responses="LBSTGruppen[2].responses"
        >
          <template v-slot:alternative-content>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        Abstraktionsprinzip
                      </strong>
                    </td>
                    <td>
                      erfüllt
                    </td>
                    <td>
                      Die Form der gezählten Elemente ist beliebig. Somit können
                      auch Plättchen gezählt werden.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Kardinalprinzip
                      </strong>
                    </td>
                    <td>
                      nicht erkennbar
                    </td>
                    <td>
                      Es wird keine explizite Aussage über die Anzahl der
                      Plättchen getroffen.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Prinzip der stabilen Reihenfolge
                      </strong>
                    </td>
                    <td>
                      nicht erfüllt
                    </td>
                    <td>
                      Die feste Reihenfolge der Zahlwörter wird nicht
                      eingehalten, da "Sieben" in zwei Zahlwörter zerlegt wurde.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Prinzip der Irrelevanz der Reihenfolge
                      </strong>
                    </td>
                    <td>
                      erfüllt
                    </td>
                    <td>
                      Die Elemente können in einer beliebigen Anordnung und
                      Reihenfolge gezählt werden.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Eins-Eins-Prinzip
                      </strong>
                    </td>
                    <td>
                      nicht erfüllt
                    </td>
                    <td>
                      Dem Zahlwort "Sieben" werden zwei statt einem Plättchen
                      zugeordnet.
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </LernbausteinGruppen>
      </div>
    </AppButtonCollapse>
    <p>
      Insgesamt können an vielen Aussagen und Bearbeiten von Aufgaben der Kinder
      bereits Aussagen dazu getroffen werden, inwiefern die unterschiedlichen
      Zählprinzipien angewendet werden können. Neben den Zählprinzipien ist aber
      auch die Zählentwicklung für ein tragfähiges Zahlverständis relevant.
    </p>
    <p>
      Die Zählentwicklung setzt bereits im Vorschulalter ein. Ein Hindernis im
      Erwerb der Zahlwortreihe ist, dass die Zahlwörter im Deutschen nicht
      konsistent gebildet werden. Auch die Versprachlichung erschwert diesen
      Erwerb (z. B. indem die Einer ab der Zahl 13 zuerst ausgesprochen werden)
      (vgl. Krauthausen & Scherer, 2007). Um die Entwicklung der Zählkompetenzen
      klassifizieren zu können, formulieren Bönig und Kolleg:innen (2017)
      folgende Entwicklungsphasen der Zählentwicklung:
    </p>

    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>1 - verbales Zählen</strong></td>
            <td>
              Die Zahlenfolge wird wie ein zusammenhängendes Wort aufgefasst,
              ohne dass etwas gezählt wird. <br />
              &rarr; Zählen als Ganzheit
            </td>
          </tr>
          <tr>
            <td><strong>2 - asynchrones Zählen</strong></td>
            <td>
              Die Zahlwörter werden voneinander unterschieden und können genutzt
              werden, um einzelne Gegenstände zu zählen. Die Startzahl ist in
              dieser Phase stets die „1“. Mehrfachzählungen oder Auslassungen
              einzelner Objekte treten häufig auf. <br />
              &rarr;Zählen als starre Folge
            </td>
          </tr>
          <tr>
            <td><strong>3 – geordnetes Zählen </strong></td>
            <td>&rarr; Zählen als flexible Folge</td>
          </tr>
          <tr>
            <td><strong>3.1 teilweise flexible Folge</strong></td>
            <td>
              Das Zählen kann von einer beliebigen Zahl ausgehen. Auch ist es
              Kindern in dieser Phase möglich, Vorgänger und Nachfolger zu
              benennen und erste Zahlenfolgen rückwärts zu erstellen.
            </td>
          </tr>
          <tr>
            <td><strong>3.2 vollständig flexible Folge</strong></td>
            <td>
              Auch die Anzahl der erfolgten Zählschritte kann erfasst werden.
            </td>
          </tr>
          <tr>
            <td><strong>4 – resultatives & abkürzendes Zählen</strong></td>
            <td>
              In dieser Phase ist es möglich, sicher in Schritten zu zählen und
              auch um eine bestimmte Anzahl rückwärtszuzählen. <br />
              &rarr; Zählen als sichere Folge
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />

    <p>Betrachten Sie dazu die folgenden Aussagen von Kindern:</p>

    <div style="background-color: white">
      <LernbausteinSortieren
        :id="LBSTSortieren[0].id"
        :workOrder="LBSTSortieren[0].workOrder"
        :elements="LBSTSortieren[0].elements"
        :responses="LBSTSortieren[0].responses"
        :left="LBSTSortieren[0].left"
        :right="LBSTSortieren[0].right"
      >
        <template v-slot:alternative-content>
          <p>
            Bei der Entwicklung der Zählkompetenz von Schüler:innen ist es
            wichtig, dass das Zählen immer flexibler wird. Zu Beginn wird die
            Zahlwortreihe häufig von Eins an aufgezählt und auch schon genutzt,
            um Mengen abzuzählen. Danach können Vorgänger und Nachfolger einer
            Zahl benannt werden. Darauf folgt, dass von einer beliebigen Zahl
            aus weiter und später rückwärts gezählt werden kann.
          </p>
        </template>
      </LernbausteinSortieren>
    </div>
    <p>
      Bei den Grundvorstellungen zu Zahlen kann also festgehalten werden, dass
      das Zählen, unterschiedliche Zahlaspekte und die Zählentwicklung der
      Kinder genauer betrachtet werden sollten.
    </p>

    <AppLiteraturZV />
    <AppBottomNavZV
      next="/zahlverstaendnis/hintergrundwissen/darstellungen"
      back="/zahlverstaendnis/hintergrundwissen"
    />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
import AppLiteraturZV from "@/common/AppLiteraturZV";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinSortieren from "@/components/faledia/explorativeElemente/LernbausteinSortieren";

import Gruppe_zp_alt from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Gruppen_Zaehlprinzipien_alt";
import Gruppe_zp_alt_2 from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Gruppen_Zaehlprinzipien_alt_2";
import Gruppe_zp_alt_3 from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Gruppen_Zaehlprinzipien_alt_3";
import Sortieren_zaehlen from "@/components/faledia/seiten/zahlverstaendnis/LBST/LBST_ZV_Sortieren_Zaehlen";

export default {
  components: {
    AppBottomNavZV,
    AppLiteraturZV,
    LernbausteinGruppen,
    LernbausteinSortieren,
    AppButtonCollapse,
  },
  data: () => ({
    LBSTGruppen: [Gruppe_zp_alt, Gruppe_zp_alt_2, Gruppe_zp_alt_3],
    LBSTSortieren: [Sortieren_zaehlen],
  }),
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
</style>
