function createObject() {
  let example = {
    id: "LBST_ZV_Gruppen_Zaehlprinzipien_alt",
    showGroupNames: true,
    checkAfterHowManyItems: 0,
    checkAfterExactItemCount: false,
    workOrder: `In der nachfolgenden Abbildung werden einige Zählprinzipien oder ihre Verletzung dargestellt. Bestimmen Sie, welches Zählprinzip erfüllt oder verletzt wird oder zu welchem keine Aussage getroffen werden kann.`,
    workOrderImage: require("@/assets/zv/Gruppen/zp/aufgabe_alt_2.png"),
    possibleCategories: [
      {
        id: 1,
        name: "Erfüllt",
      },
      {
        id: 2,
        name: "Nicht erfüllt",
      },
      {
        id: 3,
        name: "Nicht erkennbar",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/zv/Gruppen/zp/01.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/zv/Gruppen/zp/02.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/zv/Gruppen/zp/03.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/zv/Gruppen/zp/04.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 5,
        image: require("@/assets/zv/Gruppen/zp/05.png"),
        category: 1,
        flex: 3,
      },
    ],
  };

  const teilerfuellt =
    "Diese Prinzipen sind klar in der Darstellung erkennbar. Allerdings sind noch weitere Zählprinzipien hier erfüllt. Welche? ";

  const viererfuellt =
    "Genau! Diese Prinzipien sind klar in der Darstellung erkennbar. ";

  const einnichterfuellt = "Es kann keine Aussage darüber getroffen werden, ob das Kardinalzahlprinzip beherrscht wird, da die Anzahlbestimmung in dieser Darstellung nicht fokussiert wird. ";

  const einnichterkennbar =
    "Genau! Das Kardinalzahlprinzip ist hier nicht erkennbar, da die Reihenfolge der Zahlwörter aufgrund der Anordnung der Plättchen beliebig sein könnte. ";

  example.responses = [
    {
      id: "400",
      category: example.possibleCategories[0],
      text: viererfuellt,
    },
    {
      id: "401",
      text: viererfuellt + einnichterkennbar,
    },
    {
      id: "300",
      text: teilerfuellt,
    },
    {
      id: "301",
      text: teilerfuellt + einnichterkennbar,
    },
    {
      id: "200",
      text: teilerfuellt,
    },
    {
      id: "201",
      text: teilerfuellt + einnichterkennbar,
    },
    {
      id: "100",
      text: teilerfuellt,
    },
    {
      id: "101",
      text: teilerfuellt + einnichterkennbar,
    },
    {
      id: "001",
      category: example.possibleCategories[2],
      text: einnichterkennbar,
    },
    {
      id: "000",
      category: example.possibleCategories[1],
      text: einnichterfuellt,
    },
  ];

  return example;
}

export default createObject();
