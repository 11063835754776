function createObject() {
    let example = {
        id: "LBST_ZV_Gruppen_Zaehlprinzipien_alt",
        showGroupNames: true,
        checkAfterHowManyItems: 0, //Kleinste Gruppe ist Null
        checkAfterExactItemCount: false, // Muss false sein da nicht alle Kategorien die gleiche Anzahl haben
        workOrder: `In der nachfolgenden Abbildung werden einige Zählprinzipien oder ihre Verletzung dargestellt. Bestimmen Sie, welches Zählprinzip erfüllt oder verletzt wird oder zu welchem keine Aussage getroffen werden kann.`,
        workOrderImage: require("@/assets/zv/Gruppen/zp/aufgabe_alt_1.png"),
        possibleCategories: [
            {
                id: 1,
                name: "Erfüllt",
            },
            {
                id: 2,
                name: "Nicht erfüllt",
            },
            {
                id: 3, 
                name: "Nicht erkennbar",
            },
        ],
        elements: [
            {
                id: 1,
                image: require("@/assets/zv/Gruppen/zp/01.png"),
                category: 1,
                flex: 3,
            },
            {
                id: 2,
                image: require("@/assets/zv/Gruppen/zp/02.png"),
                category: 1,
                flex: 3,
            },
            {
                id: 3,
                image: require("@/assets/zv/Gruppen/zp/03.png"),
                category: 3,
                flex: 3,
            },
            {
                id: 4,
                image: require("@/assets/zv/Gruppen/zp/04.png"),
                category: 1,
                flex: 3,
            },
            {
                id: 5,
                image: require("@/assets/zv/Gruppen/zp/05.png"),
                category: 1,
                flex: 3,
            },
        ],
    };

    const teilerfuellt = "Diese Prinzipen sind klar in der Darstellung erkennbar. Allerdings sind noch weitere Zählprinzipien hier erfüllt. Welche? ";

    const viererfuellt = "Genau! Diese Prinzipien sind klar in der Darstellung erkennbar. ";

    const einnichterfuellt = "Diese Einschätzung kann nicht belegt werden: Das Prinzip der stabilen Reihenfolge könnte in diesem Beispiel erfüllt sein, da es sich auf die Zahlwörter bezieht. Jedoch geht nicht aus der Darstellung hervor, ob diese auch in der entsprechenden Reihenfolge durchlaufen wurden. ";

    const einnichterkennbar = "Genau! Das Prinzip der stabilen Reihenfolge ist hier nicht erkennbar, da die Reihenfolge der Zahlwörter aufgrund der Anordnung der Plättchen beliebig sein könnte. ";

    example.responses = [
        {
            id: '400',
            category: example.possibleCategories[0],
            text: viererfuellt,
        },
        {
            id: '401',
            text: viererfuellt + einnichterkennbar,
        },
        {
            id: '300',
            text: teilerfuellt,
        },
        {
            id: '301',
            text: teilerfuellt + einnichterkennbar,
        },
        {
            id: '200',
            text: teilerfuellt,
        },
        {
            id: '201',
            text: teilerfuellt + einnichterkennbar,
        },
        {
            id: '100',
            text: teilerfuellt,
        },
        {
            id: '101',
            text: teilerfuellt + einnichterkennbar,
        },
        {
            id: '001',
            category: example.possibleCategories[2],
            text: einnichterkennbar,
        },
        {
            id: '000',
            category: example.possibleCategories[1],
            text: einnichterfuellt,
        },
    ]

    return example;
}

export default createObject();